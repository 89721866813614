import React from 'react';
import styled from 'styled-components';
import { colors, devices, fontWeights } from '../styles/theme';
import ImageCarousel from './ImageCarousel';
import { GatsbyImage } from 'gatsby-plugin-image';
import { FeatureItem, FeatureText } from '../styles/shared';

const ImagesWithTexts = ({ pairs, images, icons }) => {
  return (
    <Container>
      <Carousel images={images}>
        {/*render function for ImageCarousel component*/}
        {currentIndex => (
          <>
            <Text>
              <FeatureItem key={currentIndex}>
                <GatsbyImage
                  image={icons[currentIndex]}
                  style={{ height: 'max-content' }}
                  alt={pairs[currentIndex].text.heading}
                />
                <FeatureText>
                  <h3>{pairs[currentIndex].text.heading}</h3>
                  <p>{pairs[currentIndex].text.paragraph}</p>
                </FeatureText>
              </FeatureItem>
            </Text>
            <Rectangle />
          </>
        )}
      </Carousel>
    </Container>
  );
};
const Container = styled.div`
  width: 100%;
  padding-bottom: 56.25%; // 16:9 aspect ratio
  position: relative;
  display: grid;
  @media (${devices.xs}) {
    margin-top: 9rem;
  }
`;
const Carousel = styled(ImageCarousel)`
  position: absolute !important;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`;
const Text = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  max-width: 50%;
  z-index: 2;
  padding: 3vw;
  background-color: ${colors.white};
  font-weight: ${fontWeights.bold};
  font-size: 3rem;
  line-height: 4rem;
  border-bottom-left-radius: 0.375rem;

  @media (${devices.xs}) {
    top: -9rem;
    max-width: 100%;
  }
`;
const Rectangle = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 17%;
  height: 7.2%;
  background-color: ${colors.white};
  z-index: 2;
  border-top-right-radius: 0.375rem;
  @media (${devices.xs}) {
    display: none;
  }
`;

export default ImagesWithTexts;
