import * as React from 'react';
import { useEffect, useState } from 'react';
import Layout from '../components/Layout';
import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';
import { graphql, Link } from 'gatsby';
import { colors, devices, fontWeights } from '../styles/theme';
import {
  communityLotDataConfig,
  homePageContent,
  ownRentModalContent,
} from '../utils/dataConfig';
import {
  bounce,
  BuildLink,
  ButtonLink,
  FeatureItem,
  FeatureText,
  Intent,
  LearnMoreLink,
  OptionInfo,
  PaddedGrid,
  StartingOption,
  StartingOptions,
  TextBlock,
} from '../styles/shared';
import ImagesWithTexts from '../components/ImagesWithTexts';
import CrossIcon from '../assets/icons/cross.svg';
import CheckIcon from '../assets/icons/check.svg';
import Modal from '../components/Modal';
import OwnRent from '../components/modals/OwnRent';
import RadioToggle from '../components/RadioToggle';
import { AiFillPlayCircle } from '@react-icons/all-files/ai/AiFillPlayCircle';
import ModalVideo from '../components/modals/ModalVideo';
import { BsChevronDown } from '@react-icons/all-files/bs/BsChevronDown';
import { storeReferralData } from '../utils/helpers';
import SEO from '../components/SEO';

const section4Icons = [
  { icon: CrossIcon, alt: 'No' },
  { icon: CrossIcon, alt: 'No' },
  { icon: CheckIcon, alt: 'Yes' },
];

const communityLotMap = {
  mountain: 'forest',
  'lake-travis': 'lake-view',
  bastrop: 'forest',
};
const gridImagePositionMap = ['90% 50%', '50% 50%', '50% 50%', '50% 50%'];

const IndexPage = ({ data, location }) => {
  // store referral params
  useEffect(() => {
    if (location.search) {
      const params = new URLSearchParams(location.search);
      storeReferralData(params);
    }
  }, []);
  const heroImage = data.allFile.nodes.find(
    image => image.name === 'home-hero'
  );
  const videoThumbnail = data.allFile.nodes.find(
    image => image.name === 'home-video-thumbnail'
  ).childImageSharp.gatsbyImageData;
  const section3Images = data.allFile.nodes
    .filter(image => image.name.startsWith('home-section-3-image-'))
    .sort((a, b) => (a.name > b.name ? 1 : -1))
    .map(image => ({ image }));
  const section3CarouselPairs = section3Images.map((image, index) => ({
    text: homePageContent.section3.carousel[index],
  }));
  const section3Icons = data.allFile.nodes
    .filter(image => image.name.startsWith('home-section-3-icon-'))
    .sort((a, b) => (a.name > b.name ? 1 : -1))
    .map(image => image.childImageSharp.gatsbyImageData);
  const gridImages = data.allFile.nodes
    .filter(image => image.name.startsWith('home-grid-'))
    .sort((a, b) => (a.name > b.name ? 1 : -1));
  const section5Image = data.allFile.nodes.find(
    image => image.name === 'home-section-5'
  ).childImageSharp.gatsbyImageData;
  const decorImages = data.decor.nodes
    .sort((a, b) => (a.name > b.name ? 1 : -1))
    .map(image => image.childImageSharp.gatsbyImageData);
  const communityImages = data.communities.nodes;

  const [modal, setModal] = useState(null);
  const handleClose = () => setModal(null);

  const [intents, setIntents] = useState({});
  const changeIntent = (community, intent) =>
    setIntents(prev => ({
      ...prev,
      [community]: intent,
    }));

  return (
    <>
      <SEO title='Home' />
      <Layout>
        <Container>
          <HeroImageWithText>
            <GatsbyImage
              alt='a complex modular building'
              image={heroImage.childImageSharp.gatsbyImageData}
              style={{
                gridArea: '1 / 1',
                borderRadius: '0.375rem',
              }}
            />
            <div className='overlay' />
            <div className='text'>
              <h1>{homePageContent.hero.heading}</h1>
              <h2>{homePageContent.hero.subheading}</h2>
              <a href='/builder/'>Start Building</a>
            </div>
          </HeroImageWithText>
        </Container>
        <Section1>
          {/* <HeroContainer>
            <HeroText>
              <h1>{homePageContent.hero.heading}</h1>
              <h2>{homePageContent.hero.subheading}</h2>
            </HeroText>
            <HeroLink to='/builder/'>Build Your Home</HeroLink>
            <HeroArrow color={colors.white} size='2.5rem' />
            <HeroVideo muted loop autoPlay playsInline>
              <source src={homePageContent.hero.video} type='video/mp4' />
            </HeroVideo>
          </HeroContainer> */}
          {/*<Section1VideoOverlay>*/}
          {/*  <img src={LogoSVG} alt='neu community logo' />*/}
          {/*</Section1VideoOverlay>*/}
          {/*<Section1Image image={section1Image} alt='section 1 hero' />*/}
          <PaddedGrid>
            <TextBlock>
              <h1>{homePageContent.section1.heading}</h1>
              <p>{homePageContent.section1.paragraph}</p>
            </TextBlock>
            <Section1Decor
              image={decorImages[0]}
              style={{ position: 'absolute' }}
              alt='background decor art'
            />
          </PaddedGrid>
        </Section1>
        <CommunityList>
          <StartingOptions>
            {communityLotDataConfig.list.map(community => {
              const communityInfo = communityLotDataConfig[community];
              return (
                <StartingOption key={community}>
                  <CommunityImage
                    image={
                      communityImages.find(
                        image => image.name === `home-community-${community}`
                      ).childImageSharp.gatsbyImageData
                    }
                    alt={`${communityInfo.title} ${communityLotMap[community]} lot`}
                  />
                  <OptionInfo>
                    <div className='content'>
                      <h2>{communityInfo.title}</h2>
                      <div>
                        <p>{communityInfo.description}</p>
                        <ul>
                          {communityInfo.features.map(feature => (
                            <li key={feature}>&bull; {feature}</li>
                          ))}
                        </ul>
                      </div>
                    </div>
                    <div className='actions'>
                      <Intent>
                        <h3>Start building as:</h3>
                        <RadioToggle
                          current={intents[community] || 'own'}
                          left={{ value: 'own', title: 'owner' }}
                          right={{ value: 'rent', title: 'renter' }}
                          handleClick={intent =>
                            changeIntent(community, intent)
                          }
                        />
                        <LearnMoreLink to='/own-vs-rent/'>
                          learn more
                        </LearnMoreLink>
                      </Intent>
                      <BuildLink
                        to={`/builder/?src=home&c=${community}&l=${
                          communityLotMap[community]
                        }&i=${intents[community] || 'own'}`}
                      >
                        Build
                      </BuildLink>
                    </div>
                  </OptionInfo>
                </StartingOption>
              );
            })}
          </StartingOptions>
        </CommunityList>
        <Section4>
          <h1>{homePageContent.section4.heading}</h1>
          <Section4Grid>
            {gridImages.map((image, index) => (
              <GatsbyImage
                image={image.childImageSharp.gatsbyImageData}
                key={image.name}
                alt={image.name}
                objectPosition={gridImagePositionMap[index]}
              />
            ))}
          </Section4Grid>
          <Section4Content>
            <div className='left'>
              <h2>{homePageContent.section4.content.heading}</h2>
              <ButtonLink className='desktop' to='/builder/'>
                Start Building
              </ButtonLink>
            </div>
            <p>{homePageContent.section4.content.paragraph}</p>
            <ButtonLink className='mobile' to='/builder/'>
              Start Building
            </ButtonLink>
          </Section4Content>
        </Section4>
        <Section2>
          <Section1Decor
            image={decorImages[0]}
            style={{ position: 'absolute' }}
            alt='background decor art'
          />
          <Section2TextBlock>
            <h1>{homePageContent.section2.heading}</h1>
            <h2>{homePageContent.section2.subheading}</h2>
            <p>{homePageContent.section2.paragraph}</p>
            <ButtonLink to='/builder/'>
              <p>
                <span>Start Building</span>
              </p>
            </ButtonLink>
          </Section2TextBlock>
          <PlayButton onClick={() => setModal('explainer')}>
            <Section2Image
              image={videoThumbnail}
              alt='video thumbnail'
              objectFit='contain'
              style={{ gridArea: '1 / 1' }}
            />
            <AiFillPlayCircle size='4rem' className='play-icon' />
          </PlayButton>
          <Section2Decor
            image={decorImages[1]}
            style={{ position: 'absolute' }}
            alt='background decor art'
          />
        </Section2>
        <Section3>
          <ImagesWithTexts
            pairs={section3CarouselPairs}
            images={section3Images}
            icons={[
              section3Icons[1],
              section3Icons[0],
              section3Icons[7],
              section3Icons[4],
              section3Icons[6],
            ]}
          />
          <h1 className='heading'>{homePageContent.section3.heading}</h1>
          <FeatureGrid>
            {homePageContent.section3.features.map((feature, index) => (
              <FeatureItem key={index}>
                <GatsbyImage
                  image={section3Icons[index]}
                  style={{ height: 'max-content' }}
                  alt={feature.heading}
                />
                <FeatureText>
                  <h3>{feature.heading}</h3>
                  <p>{feature.paragraph}</p>
                </FeatureText>
              </FeatureItem>
            ))}
            <Section3Decor
              image={decorImages[2]}
              style={{ position: 'absolute' }}
              alt='background decor art'
            />
          </FeatureGrid>
        </Section3>

        <Section5>
          <PaddedGrid>
            <TextBlock>
              <h1>{homePageContent.section5.heading}</h1>
              <p>{homePageContent.section5.paragraph}</p>
            </TextBlock>
            <Section5Image image={section5Image} alt='section 5 image' />
          </PaddedGrid>
        </Section5>
      </Layout>
      {modal === 'ownRent' && (
        <Modal
          anchorStyle={{ width: '100%', height: '100%' }}
          handleClose={handleClose}
        >
          <OwnRent handleClose={handleClose} />
        </Modal>
      )}
      {modal === 'explainer' && (
        <Modal
          anchorStyle={{ width: '100%', height: '100%' }}
          handleClose={handleClose}
        >
          <ModalVideo
            handleClose={handleClose}
            src={ownRentModalContent.video.url}
          />
        </Modal>
      )}
    </>
  );
};
const Container = styled.section`
  padding: calc(var(--body-padding-y) / 3) var(--body-padding-x)
    calc(var(--body-padding-y));
  display: grid;
  row-gap: 4rem;
`;
const HeroImageWithText = styled.div`
  display: grid;
  min-height: 35rem;

  .overlay {
    grid-area: 1 / 1;
    z-index: 1;
    width: 100%;
    height: 100%;
    border-radius: 0.375rem;
    background: linear-gradient(
      to top,
      ${colors.darkTransparent},
      transparent 50%
    );
  }

  .text {
    grid-area: 1 / 1;
    z-index: 2;
    color: ${colors.white};
    justify-self: start;
    align-self: end;
    margin: 6% 4%;
    max-width: 34rem;
    // refer to shared/TextBlock
    display: grid;
    row-gap: 1.5rem;
    h1 {
      font-weight: ${fontWeights.medium};
      font-size: 4rem;
      line-height: 4.5rem;
      filter: drop-shadow(0 1px 4px ${colors.darkTransparent});
    }

    p {
      font-size: 1rem;
      line-height: 2rem;
    }

    a {
      font-weight: ${fontWeights.medium};
      height: 3.5rem;
      border-radius: 0.375rem;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: ${colors.blue};
      color: ${colors.white};

      :hover {
        filter: brightness(1.5);
      }
    }

    @media (${devices.s}) {
      row-gap: 1rem;
      h1 {
        font-size: 3.4rem;
        line-height: 5.1rem;
      }
    }
    @media (${devices.xs}) {
      row-gap: 0.75rem;
      h1 {
        font-size: 2.6rem;
        line-height: 3.5rem;
      }
    }
  }
`;
const Section1 = styled.section`
  display: grid;
  row-gap: var(--image-text-row-gap);
`;
const HeroContainer = styled.div`
  display: grid;
  height: calc(100vh - var(--nav-bar-height) - var(--banner-height));
`;
const HeroText = styled.div`
  grid-area: 1 / 1;
  width: 100%;
  height: 100%;
  padding-bottom: 4%;
  color: ${colors.white};
  background-color: rgba(0, 0, 0, 0.15);
  z-index: 1;
  display: grid;
  row-gap: 0.5rem;
  justify-items: center;
  align-content: center;
  --font-size: 1.8rem;
  text-align: center;

  > h1 {
    font-weight: ${fontWeights.regular};
    font-size: calc(var(--font-size) * 2);
    letter-spacing: 0.2em;
  }

  > h2 {
    font-weight: ${fontWeights.regular};
    font-size: var(--font-size);
    letter-spacing: 0.22em;
  }

  @media (${devices.s}) {
    --font-size: 1.6rem;
  }
  @media (${devices.xs}) {
    --font-size: 1rem;
  }
`;
const HeroLink = styled(Link)`
  color: ${colors.white};
  font-size: 1rem;
  width: 12rem;
  height: 3.5rem;
  border: 1px solid ${colors.white};
  border-radius: 0.375rem;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-area: 1 / 1;
  justify-self: center;
  align-self: end;
  margin-bottom: 16vh;
  z-index: 1;

  :hover {
    background-color: ${colors.blue};
    border-color: transparent;
  }

  transition: all 0.15s ease-in;
  @media (${devices.xs}) {
    width: 10rem;
    height: 2.5rem;
  }
`;
const HeroVideo = styled.video`
  grid-area: 1 / 1;
  object-fit: cover;
  width: 100%;
  height: calc(100vh - var(--nav-bar-height) - var(--banner-height));
  background: linear-gradient(#b3c2fc, #cce5fc 50%, #1f3e1f);
  //margin: 2vw;
  //border-radius: 0.375rem;
  @media (${devices.s}) {
    //margin: var(--body-padding-x);
  }
`;
const HeroArrow = styled(BsChevronDown)`
  grid-area: 1 / 1;
  align-self: end;
  justify-self: center;
  z-index: 1;
  margin-bottom: 3vh;
  animation: 2.5s ${bounce} infinite ease;
`;
const Section1VideoOverlay = styled.div`
  grid-area: 1/1;
  display: grid;
  justify-items: start;
  align-items: start;
  margin: 5vw;
  z-index: 1;
  opacity: 0.5;

  img {
    height: 5vw;
  }
`;
const Section1Image = styled(GatsbyImage)`
  margin: 1.5rem;
  border-radius: 0.375rem;
  position: relative;
  //height: 56vh;
  //max-height: 720px;
  max-height: 85vh;
  @media (${devices.s}) {
    margin: var(--body-padding-x);
  }
`;
const Section1Decor = styled(GatsbyImage)`
  top: calc(var(--image-text-row-gap) * -1);
  right: 0;
  width: 49%;
  z-index: -1;
`;
const CommunityImage = styled(GatsbyImage)`
  height: 17vw;
  @media (${devices.xs}) {
    height: 45vw;
  }
`;
const Section2 = styled(PaddedGrid)`
  position: relative;
  margin-top: 15.5rem;
  align-items: center;
  @media (${devices.s}) {
    margin-top: 7rem;
  }
`;
const Section2Decor = styled(GatsbyImage)`
  left: 0;
  top: 100%;
  width: 30%;
  z-index: -1;
`;
const Section2TextBlock = styled(TextBlock)`
  padding: var(--text-block-padding-y) var(--text-block-padding-right)
    var(--text-block-padding-y) 0;

  > a {
    margin-top: 2rem;
  }

  h2 {
    font-weight: ${fontWeights.bold};
    font-size: 1.375rem;
    line-height: 2.125rem;
  }

  @media (${devices.s}) {
    h1 {
      white-space: initial;
    }

    > a {
      margin-top: 1rem;
      margin-right: -25%;
      justify-self: center;
    }
  }
  @media (${devices.xs}) {
    > a {
      margin-right: 0;
      width: 100%;
      background-color: ${colors.blue};
      color: ${colors.white};
      border-color: transparent;
    }
  }
`;
const Section2Image = styled(GatsbyImage)`
  border-radius: 0.375rem;
  height: 100%;
`;

const Section3 = styled.section`
  margin-top: 16.5rem;
  position: relative;
  display: grid;
  @media (${devices.s}) {
    margin-top: 5rem;
  }

  h1.heading {
    justify-self: center;
    font-weight: ${fontWeights.bold};
    font-size: 4rem;
    line-height: 4.5rem;
    margin-top: 5rem;
    padding: 0 var(--body-padding-x);
    @media (${devices.s}) {
      font-size: 2.6rem;
      line-height: 3.5rem;
    }
  }
`;
const Section3Decor = styled(GatsbyImage)`
  top: calc(var(--features-margin-top) * -1);
  left: 0;
  width: 7%;
  z-index: -1;
`;
const FeatureGrid = styled(PaddedGrid)`
  //margin-top: 11rem;
  margin-top: 6rem;
  padding: 0 calc(var(--body-padding-x) + 10vw);
  row-gap: 4.5rem;
  @media (${devices.s}) {
    padding: 0 calc(var(--body-padding-x) + 3vw);
    margin-top: 5rem;
  }
  @media (${devices.xs}) {
    row-gap: 2.6rem;
    margin-top: 3rem;
  }
`;

const Section4 = styled.section`
  margin-top: 7rem;
  padding: 0 var(--body-padding-x);

  > h1 {
    font-weight: ${fontWeights.bold};
    font-size: 4rem;
    line-height: 4.5rem;
  }

  @media (${devices.s}) {
    margin-top: 7rem;
  }
`;
const Section4Grid = styled.div`
  margin-top: 1.875rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 2rem;

  > :nth-child(2),
  > :nth-child(3) {
    grid-column: span 2;
  }

  @media (${devices.xs}) {
    > * {
      grid-column: 1 / -1;
    }

    > :nth-child(2),
    > :nth-child(3) {
      grid-column: 1 / -1;
    }
  }
`;

const Section4Content = styled(PaddedGrid)`
  padding: 0;
  margin-top: 5.75rem;

  .left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  & h2 {
    font-weight: ${fontWeights.bold};
    font-size: 2.125rem;
    line-height: 3.5rem;
  }

  a {
    margin-top: 1.5rem;

    &.mobile {
      display: none;
    }
  }

  > p {
    font-size: 1rem;
    line-height: 2rem;
  }

  @media (${devices.s}) {
    margin-top: 1.75rem;
  }
  @media (${devices.xs}) {
    grid-template-columns: auto;

    > :first-child {
      margin-bottom: 1.5rem;
    }

    a.desktop {
      display: none;
    }

    a.mobile {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      background-color: ${colors.blue};
      color: ${colors.white};
      border-color: transparent;
    }
  }
`;

const Comparisons = styled.div`
  grid-column: 1 / -1;
  margin-top: 12rem;
  display: grid;
  column-gap: 0.5rem;
  grid-template-columns: repeat(3, 1fr);
  justify-content: space-around;
  justify-items: center;
  position: relative;

  & h3 {
    font-weight: ${fontWeights.bold};
    font-size: 1.375rem;
    line-height: 2.125rem;
    margin-top: 2.25rem;
    text-align: center;
  }

  & p {
    font-size: 1rem;
    line-height: 2rem;
    text-align: center;
  }

  @media (${devices.s}) {
    margin-top: 4rem;
    & h3 {
      margin-top: 1.5rem;
    }
  }
  @media (${devices.xs}) {
    grid-template-columns: auto;
    row-gap: 5rem;
  }
`;
const Section4Decor = styled(GatsbyImage)`
  right: calc(var(--body-padding-x) * -1);
  top: 0;
  width: var(--body-padding-x);
  z-index: -1;
  @media (${devices.s}) {
    width: 8vw;
  }
`;
const CompareItem = styled.div`
  display: grid;
  justify-items: center;
  max-width: 15.5rem;
  @media (${devices.s}) {
    > img {
      width: 2.9rem;
    }
  }
`;
const Section5 = styled.div`
  margin-top: 16rem;
  @media (${devices.s}) {
    margin-top: 5rem;
  }
`;
const Section5Image = styled(GatsbyImage)`
  margin: 2.75rem 0 10.375rem;
  grid-column: span 2;
  height: 29.5rem;
  @media (${devices.s}) {
    margin-bottom: 3.8rem;
  }
  @media (${devices.xs}) {
    margin-bottom: 1.1rem;
  }
`;
const CommunityList = styled.section`
  padding: 0 var(--body-padding-x);
  margin-top: 5rem;
  @media (${devices.s}) {
    margin-top: 3rem;
  }
`;
const PlayButton = styled.button`
  display: grid;

  :hover {
    .play-icon {
      color: ${colors.blue};
    }
  }

  .play-icon {
    justify-self: center;
    align-self: center;
    grid-area: 1 / 1;
    z-index: 1;
  }

  @media (${devices.s}) {
    order: -1;
    grid-column: 1 / -1;
    margin: 2rem;
    border-radius: 0.375rem;
  }
`;
export default IndexPage;
export const pageQuery = graphql`
  query getHomeImages {
    allFile(filter: { name: { regex: "/^home/" } }) {
      nodes {
        name
        childImageSharp {
          gatsbyImageData(quality: 70, width: 1440, placeholder: BLURRED)
        }
      }
    }
    decor: allFile(filter: { name: { regex: "/^home-decor/" } }) {
      nodes {
        name
        childImageSharp {
          gatsbyImageData(quality: 70, placeholder: NONE, width: 800)
        }
      }
    }
    communities: allFile(filter: { name: { regex: "/^home-community/" } }) {
      nodes {
        name
        childImageSharp {
          gatsbyImageData(quality: 70, height: 300, placeholder: BLURRED)
        }
      }
    }
  }
`;
